import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

export default function ModalEditarCategoriaProducto({ open, onClose, updateCatProductoList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    const [nombre, setNombre] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingSubmit, setloadingSubmit] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarCatProducto();
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const cargarCatProducto = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneCatProductoAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneCatProductoOperario(id);
            }

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {
                setNombre(response.data.nombre_categoria_producto);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const catInsumoData = {
                nombre_categoria_producto: nombre,
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateCatProductoAdmin(id, catInsumoData);
            }else if(getRol() === "Operario"){
                response = await Config.putUpdateCatProductoOperario(id, catInsumoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("La categoría de producto ha sido editado correctamente.", "success");
                    setNombre('');
                    onClose();
                    updateCatProductoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateCatProductoList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Categoría de Producto</h3>

                <form>

                    {!loadingData ?

                        <>

                            <InputForm
                                id="nombre"
                                labelName="Cat. de Producto:"
                                inputType="text"
                                value={nombre}
                                funcion={(e) => setNombre(e.target.value)}
                                errors={errors.nombre_categoria_producto}
                                labelWidth="w-44"
                            />

                            {errors.nombre_categoria_producto && Array.isArray(errors.nombre_categoria_producto) && renderErrorMessages(errors.nombre_categoria_producto)}

                        </>

                        : <div className='flex justify-center items-center mb-3' style={{ height: '34px' }}>
                            <TailSpin className='' width={75} height={75}></TailSpin>
                        </div>}

                        <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-56
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-56'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Categoría Producto
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
