import axios from 'axios';

//Development
//const BASE_API_URL = "http://localhost:8000/api/v1";

//Producción
const BASE_API_URL = "https://backend-proyecto.inkadeveloper.com/api/v1";

axios.defaults.withCredentials = true;

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const token = JSON.parse(tokenString);
    return token;
}

export default {

    //Refrescar token
    refreshToken: () => {
        return axios.post(`${BASE_API_URL}/auth/refresh`, {}, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //PANEL ADMINISTRATIVO LOGIN, LOGOUT Y RECUPERAR

    getLogin: (data) => axios.post(`${BASE_API_URL}/auth/login`, data),

    getLogout: () => {

        return axios.post(`${BASE_API_URL}/auth/logout`, {}, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
    },

    postRecuperar: (data) => axios.post(`${BASE_API_URL}/mail/recuperar_contrasena`, data),
    postValidarCodigo: (data) => axios.post(`${BASE_API_URL}/mail/validar_codigo`, data),
    postCambiaContrasenia: (data) => axios.post(`${BASE_API_URL}/mail/cambiar_contrasenia`, data),

    postEnviarMensajeContacto: (data) => axios.post(`${BASE_API_URL}/mail/enviar_mensaje_contacto`, data),

    //Admin y gerente
    //Obtener todos los usuarios
    getUsersListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getUsersListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Obtener roles
    getRolesListAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getRoles`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    //Obtener usuario por ID
    getUserListAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getUserListGerente: (id) => {
        return axios.get(`${BASE_API_URL}/gerente/getUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Actualizar usuario por ID
    putUpdateUserAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/UpdateUser/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateUserGerente: (id, data) => {
        return axios.put(`${BASE_API_URL}/gerente/UpdateUser/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Eliminar usuario por ID
    deleteUserAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/DeleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteUserGerente: (id) => {
        return axios.delete(`${BASE_API_URL}/gerente/DeleteUser/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Agregar nuevo usuario
    addNewUserAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeUser`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    addNewUserGerente: (data) => {
        return axios.post(`${BASE_API_URL}/gerente/storeUser`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    //Exportar data para PDF y EXCEL
    getExportDataUsersAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getExportUsers`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* **************************************** PROVEEDORES ********************************************************************* */
    /* Administrador */
    getProveedoresListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Gerente */
    getProveedoresLisGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarProveedorAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeProveedor`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneProveedorAdmin:(id) => {
        return axios.get(`${BASE_API_URL}/admin/getProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateProveedorAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateProveedor/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteProveedorAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteProveedor/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************** CLIENTES ********************************************************************* */
    getClientesListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    getClientesListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/gerente/getClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarClienteAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCliente`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* Obtener datos para añadir o editar clientes */
    
    getTipoClientesAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getTipoClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getTipoDocumentosAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getTipoDocumentos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    getDistritosAdmin: () => {
        return axios.get(`${BASE_API_URL}/admin/getDistritos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
        });
    },

    /* ************************************************************************* */

    getOneClienteAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCliente/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateClienteAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCliente/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteClienteAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCliente/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Categorías de Insumos **************************************** */
    /* Administrador */
    getCatInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getCatInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarCatInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCategoriasInsumos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarCatInsumoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeCategoriasInsumos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatInsumoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCategoriaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatInsumoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateCategoriaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteCatInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCategoriaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Categorías de Productos **************************************** */
    /* Administrador */
    getCatProductosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriasProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getCatProductosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriasProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarCatProductoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeCategoriasProductos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarCatProductoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeCategoriasProductos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatProductoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getCategoriaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneCatProductoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getCategoriaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatProductoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateCategoriaProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateCatProductoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateCategoriaProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteCatProductoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteCategoriaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Marcas de Insumos **************************************** */
    /* Administrador */
    getMarcasInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getMarcasInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarMarcaInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeMarcaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarMarcaInsumoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeMarcaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneMarcaInsumoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateMarcaInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateMarcaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateMarcaInsumoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateMarcaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteMarcaInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteMarcaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ***************************************************************************************************************************************** */
    /* **************************************** Servicios de Específicos **************************************** */
    /* Administrador */
    getServiciosEspecificosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getServiciosEspecificosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    postGuardarServicioEspecificoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeServicioEspecifico`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarServicioEspecificoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeServicioEspecifico`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioEspecificoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioEspecificoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioEspecificoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateServicioEspecifico/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioEspecificoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateServicioEspecifico/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteServicioEspecificoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteServicioEspecifico/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Insumos **************************************** */
    /* Administrador */
    getInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para almacenar
    getSelectCategoriaInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectCategoriaInsumosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/getSelectCategoriasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectMarcasInsumosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/getSelectMarcasInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProveedoresAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getSelectProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProveedoresOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/getSelectProveedores`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarInsumoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneInsumoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateInsumoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Productos **************************************** */
    /* Administrador */
    getProductosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getProductosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para almacenar
    getSelectCategoriaProductosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectCategoriaProducto`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectCategoriaProductosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectCategoriaProducto`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarProductoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeProducto`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarProductoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeProducto`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneProductoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneProductoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateProductoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateProductoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneProductoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Insumos **************************************** */
    /* Administrador */
    getServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getServiciosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar cliente
    getSelectClientesAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectClientesOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //Listas para seleccionar servicio específico
    getSelectServiciosEspecificoAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServiciosEspecificoOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectServiciosEspecificos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarServicioOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneServicioOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateServicioOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Insumos **************************************** */
    /* Administrador */
    getIngresoInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getIngresoInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar insumo
    getSelectInsumosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectInsumosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectInsumosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarIngresoInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoInsumoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeIngresoInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoInsumoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoInsumoAlmacenero: (id) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoInsumoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateIngresoInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoInsumoOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteIngresoInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Insumos **************************************** */
    /* Administrador */
    getSalidaInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getSalidaInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getSalidaInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //******* */
    postGuardarSalidaInsumoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaInsumoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeSalidaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaInsumoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaInsumo`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaInsumoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaInsumoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaInsumoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaInsumoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateSalidaInsumo/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaInsumoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaInsumoOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteSalidaInsumo/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Productos **************************************** */
    /* Administrador */
    getIngresoProductosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoProductosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getIngresoProductosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar producto
    getSelectProductosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProductosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectProductosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarIngresoProductoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoProducto`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoProductoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeIngresoProducto`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoProductoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoProducto`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoProductoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoProductoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoProductoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoProductoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateIngresoProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoProductoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoProductoOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteIngresoProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Productos **************************************** */
    /* Administrador */
    getSalidaProductosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getSalidaProductosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getSalidaProductosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //******* */
    postGuardarSalidaProductoAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaProductos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaProductoOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeSalidaProductos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaProductoAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaProductos`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getSelectClientesAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectClientes`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getOneSalidaProductoAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaProductoOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaProductoAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaProductoOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateSalidaProducto/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaProductoAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaProductoOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteSalidaProducto/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Ingreso de Servicios **************************************** */
    /* Administrador */
    getIngresoServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getIngresoServiciosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getIngresoServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getIngresoServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //Listas para seleccionar servicio
    getSelectServiciosAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/selectServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServiciosOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/selectServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServiciosAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/selectServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //Listas para seleccionar usuario responsable
    getSelectServicioResponsableMermaAdmin: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/admin/getUsuariosResponsables`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServicioResponsableMermaOperario: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/operario/getUsuariosResponsables`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    getSelectServicioResponsableMermaAlmacenero: (globalFilter) => {
        return axios.get(`${BASE_API_URL}/almacenero/getUsuariosResponsables`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                global_filter: globalFilter
            }
        });
    },

    //******* */
    postGuardarIngresoServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeIngresoServicios`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoServicioOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeIngresoServicios`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarIngresoServicioAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeIngresoServicios`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneIngresoServicioOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateIngresoServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateIngresoServicioOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateIngresoServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneIngresoServicioOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteIngresoServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* **************************************** Salida de Servicios **************************************** */
    /* Administrador */
    getSalidaServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Operario */
    getSalidaServiciosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    /* Almacenero */
    getSalidaServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns) => {
        return axios.get(`${BASE_API_URL}/almacenero/getSalidaServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns
            }
        });
    },

    //******* */
    postGuardarSalidaServicioAdmin: (data) => {
        return axios.post(`${BASE_API_URL}/admin/storeSalidaServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaServicioOperario: (data) => {
        return axios.post(`${BASE_API_URL}/operario/storeSalidaServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    postGuardarSalidaServicioAlmacenero: (data) => {
        return axios.post(`${BASE_API_URL}/almacenero/storeSalidaServicio`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaServicioAdmin: (id) => {
        return axios.get(`${BASE_API_URL}/admin/getSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    getOneSalidaServicioOperario: (id) => {
        return axios.get(`${BASE_API_URL}/operario/getSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaServicioAdmin: (id, data) => {
        return axios.put(`${BASE_API_URL}/admin/updateSalidaServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    putUpdateSalidaServicioOperario: (id, data) => {
        return axios.put(`${BASE_API_URL}/operario/updateSalidaServicio/${id}`, data, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaServicioAdmin: (id) => {
        return axios.delete(`${BASE_API_URL}/admin/deleteSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    deleteOneSalidaServicioOperario: (id) => {
        return axios.delete(`${BASE_API_URL}/operario/deleteSalidaServicio/${id}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
    },

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX INSUMOS ******************************************* */

    /* Administrador */
    getKardexInsumosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Operario */
    getKardexInsumosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Almacenero */
    getKardexInsumosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* Gerente */
    getKardexInsumosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexInsumos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso: mostrarIngreso,
                mostrar_salida: mostrarSalida,
            }
        });
    },

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX PRODUCTOS ******************************************* */

    /* Administrador */
    getKardexProductosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_productos: mostrarIngreso,
                mostrar_salida_productos: mostrarSalida,
            }
        });
    },

    /* Operario */
    getKardexProductosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_productos: mostrarIngreso,
                mostrar_salida_productos: mostrarSalida,
            }
        });
    },

    /* Almacenero */
    getKardexProductosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_productos: mostrarIngreso,
                mostrar_salida_productos: mostrarSalida,
            }
        });
    },

    getKardexProductosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexProductos`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_productos: mostrarIngreso,
                mostrar_salida_productos: mostrarSalida,
            }
        });
    },


    /* ******************************************************************************************************* */

    /* ******************************************************************************************************* */
    /* ************************************** KARDEX SERVICIOS ******************************************* */

    /* Administrador */
    getKardexServiciosListAdmin: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/admin/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },

    /* Operario */
    getKardexServiciosListOperario: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/operario/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },

    /* Almacenero */
    getKardexServiciosListAlmacenero: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/almacenero/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },

    /* Almacenero */
    getKardexServiciosListGerente: (page, perPage, globalFilter, columnFilters, sortingColumns, fechaInicial, fechaFinal, mostrarIngreso, mostrarSalida) => {
        return axios.get(`${BASE_API_URL}/gerente/getKardexServicios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                page: page + 1,
                per_page: perPage,
                global_filter: globalFilter,
                column_filters: columnFilters,
                sorting: sortingColumns,
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                mostrar_ingreso_servicios: mostrarIngreso,
                mostrar_salida_servicios: mostrarSalida
            }
        });
    },


    /* ******************************************************************************************************* */


    /* ******************************************************************************************************* */
    
    /* ******************************************************************************************************* */
    /* ************************************** DASHBOARD Y REPORTES ******************************************* */
    //REPORTE DE USUARIOS
    /* Administrador */
    getUsuariosExportarAdmin: (fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/admin/getReporteUsuarios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },

    /* Gerente */
    getUsuariosExportarGerente: (fechaInicial, fechaFinal) => {
        return axios.get(`${BASE_API_URL}/gerente/getReporteUsuarios`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
            }
        });
    },

    /* ************************************** DASHBOARD ******************************************* */
    /* Administrador */
    getDashboardAdmin: (fechaInicial, fechaFinal, filtroAnioLineInvertidoGanado) => {
        return axios.get(`${BASE_API_URL}/admin/getDashboard`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,                
                filtro_anio_line_invertido_ganancia: filtroAnioLineInvertidoGanado,
            }
        });
    },

    /* Gerente */
    getDashboardGerente: (fechaInicial, fechaFinal, filtroAnioLineInvertidoGanado) => {
        return axios.get(`${BASE_API_URL}/gerente/getDashboard`, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            params: {
                fecha_inicial: fechaInicial,
                fecha_final: fechaFinal,
                filtro_anio_line_invertido_ganancia: filtroAnioLineInvertidoGanado,
            }
        });
    },


    /* ******************************************************************************************************* */
}