import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';

import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';

import Select from 'react-select'
import AsyncSelect from 'react-select/async';

export default function ModalAnadirCliente({ open, onClose, updateClienteList }) {

    const { getRol } = AuthUser();

    //Select import
    const [arrayTipoClientes, setArrayTipoClientes] = useState([]);
    const [arrayTipoDocumentos, setArrayTipoDocumentos] = useState([]);
    const [arrayDistritos, setArrayDistritos] = useState([]);

    const [tipoCliente, setTipoCliente] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [distrito, setDistrito] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [nro_documento, setNro_documento] = useState('');
    const [direccion, setDireccion] = useState('');
    const [celular, setCelular] = useState('');

    const [errors, setErrors] = useState({});

    const [nroDocWarning, setNroDocWarning] = useState('');

    //Carga 
    const [loadingSubmit, setloadingSubmit] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {


        if (open) {
            setLoadingData(true);
            document.addEventListener('mousedown', handleClickOutside);

            Promise.all([
                getTiposClientesAll(),
                getTiposDocumentosAll(),
                getDistritosAll()
            ]).finally(() => {
                setLoadingData(false);
            });
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
            setNroDocWarning('');
        };
    }, [open]);

    const handleNroDocumentoFocus = () => {
        if (!tipoDocumento) {
            setNroDocWarning('Por favor, seleccione un tipo de documento primero.');
        } else {
            setNroDocWarning('');
        }
    }

    const getTiposClientesAll = async () => {
        let getTipoClientesList;

        if (getRol() === "Administrador") {
            getTipoClientesList = Config.getTipoClientesAdmin();
        }

        try {
            const response = await getTipoClientesList;
            //console.log("tipos de clientes: ", response.data);
            setArrayTipoClientes(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de clientes. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const getTiposDocumentosAll = async () => {
        let getTipoDocumentosList;

        if (getRol() === "Administrador") {
            getTipoDocumentosList = Config.getTipoDocumentosAdmin();
        }

        try {
            const response = await getTipoDocumentosList;
            setArrayTipoDocumentos(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los tipos de documentos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const getDistritosAll = async () => {
        let getDistritosList;

        if (getRol() === "Administrador") {
            getDistritosList = Config.getDistritosAdmin();
        }

        try {
            const response = await getDistritosList;
            //console.log("tipos de documentos: ", response.data);
            setArrayDistritos(response.data);
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar los distritos. Vuelva a intentarlo más tarde.", "error");
        } finally {

        }
    }

    const optionsTipoClientes = arrayTipoClientes.map(tipo_cliente => ({
        value: tipo_cliente.id,
        label: `${tipo_cliente.id} - ${tipo_cliente.nombre_tipo}`
    }));

    const optionsTipoDocumentos = arrayTipoDocumentos.map(tipo_documento => ({
        value: tipo_documento.id,
        label: `${tipo_documento.nombre_documento}`
    }));

    const optionsDistritos = arrayDistritos.map(distrito => ({
        value: distrito.id,
        label: `${distrito.nombre_distrito}`
    }));

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewCliente = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const clienteData = {
            tipo_cliente_id: tipoCliente.value,
            tipo_documento_id: tipoDocumento.value,
            distrito_id: distrito.value,
            nombres: nombres,
            apellido_paterno: apellidoPaterno,
            apellido_materno: apellidoMaterno,
            nro_documento: nro_documento,
            direccion: direccion,
            celular: celular,
        };

        console.log("Data del cliente: ", clienteData);

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarClienteAdmin(clienteData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El cliente ha sido añadido correctamente.", "success");
                    setTipoCliente('');
                    setTipoDocumento('');
                    setDistrito('');
                    setNombres('');
                    setApellidoPaterno('');
                    setApellidoMaterno('');
                    setNro_documento('');
                    setDireccion('');
                    setCelular('');
                    onClose();
                    updateClienteList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateClienteList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

    function capitalizeWords(str) {
        return str.toLowerCase().replace(/\b\w/g, function(letter) {
            return letter.toUpperCase();
        });
    }

    const traerDatos = (e) => {
        e.preventDefault();
        console.log("Tipo documento: "+tipoDocumento.label);
        let tipo_documento = tipoDocumento.label;

        if(tipo_documento == "DNI"){
            fetch("https://apiperu.dev/api/dni/"+nro_documento+"?api_token=49c8f87ca6a6000eb9c1cffdf260b17247d38eb2e17c4449f48936dd45809aff")
            .then((datos) => datos.json())
            .then((datos) => {
                console.log("RENIEC: ", datos);
                if(datos.success == true){
                    setNombres(datos.data.nombres);
                    setApellidoPaterno(datos.data.apellido_paterno);
                    setApellidoMaterno(datos.data.apellido_materno);

                    notify("Se ha encontrado al cliente en RENIEC.", "success");
                }else{
                    notify("No se ha encontrado al cliente en RENIEC", "error");
                }
            });
        }else if(tipo_documento == "RUC"){
            fetch("https://apiperu.dev/api/ruc/"+nro_documento+"?api_token=49c8f87ca6a6000eb9c1cffdf260b17247d38eb2e17c4449f48936dd45809aff")
            .then((datos) => datos.json())
            .then((datos) => {
                console.log("RENIEC RUC: ", datos);
                if(datos.success == true){
                    console.log("Datos de reniec RUC: "+datos)
                    setNombres(datos.data.nombre_o_razon_social);
                    setApellidoPaterno(datos.data.nombre_o_razon_social);
                    setApellidoMaterno('');
                    setDireccion(datos.data.direccion);

                    const distrito_mayus = datos.data.distrito;
                    const formattedDistrito = capitalizeWords(distrito_mayus);
                    setDistrito({label: formattedDistrito, value: datos.data.ubigeo_sunat})

                    notify("Se ha encontrado a la empresa en RENIEC.", "success");
                }else{
                    notify("No se ha encontrado a la empresa en RENIEC", "error")
                }
            });
        }

    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Cliente</h3>

                <form>

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='tipo_documento' className='w-44 flex items-center font-normal text-[#454545]'>Tipo Documento: </label>
                        <Select options={optionsTipoDocumentos}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.tipo_documento_id)}
                            id="tipo_documento"
                            value={tipoDocumento}
                            onChange={(option, id) => { setTipoDocumento(option); console.log(option, id); setNro_documento(''); }}
                            required
                            placeholder="Seleccionar tipo de documento..."
                            noOptionsMessage={() => ""}
                            loadingMessage={() => "Cargando..."}
                        />
                    </div>

                    {errors.tipo_documento_id && Array.isArray(errors.tipo_documento_id) && renderErrorMessages(errors.tipo_documento_id)}

                    <div className='flex'>
                        <InputForm
                            id="nro_documento"
                            labelName="N° de documento:"
                            inputType="text"
                            value={nro_documento}
                            funcion={(e) => setNro_documento(e.target.value.replace(/[^0-9]/g, ""))}
                            errors={errors.nro_documento}
                            labelWidth="w-52"
                            maxLength={tipoDocumento.value === 1 ? '8' : (tipoDocumento.value === 2 ? '11' : '0')}
                            onFocus={handleNroDocumentoFocus}
                            inputStyleWidth={-4}
                        />
                        <div>
                            <button
                                className='bg-blue-800 text-white rounded text-sm ms-2 px-1'
                                id="boton_buscar_reniec"
                                style={{ padding: '7px 8px' }}
                                onClick={traerDatos}
                            >
                                CONSULTAR RENIEC
                            </button>
                        </div>
                    </div>

                    {nroDocWarning && <div style={{ marginLeft: '136px' }}><p className="text-red-500 text-sm -mt-3 mb-3">{nroDocWarning}</p></div>}
                    {errors.nro_documento && Array.isArray(errors.nro_documento) && renderErrorMessages(errors.nro_documento)}


                    <InputForm
                        id="nombres"
                        labelName="Nombres:"
                        inputType="text"
                        value={nombres}
                        funcion={(e) => setNombres(e.target.value)}
                        errors={errors.nombres}
                        labelWidth="w-44"
                    />

                    {errors.nombres && Array.isArray(errors.nombres) && renderErrorMessages(errors.nombres)}

                    <InputForm
                        id="apellido_paterno"
                        labelName="Apellido Paterno:"
                        inputType="text"
                        value={apellidoPaterno}
                        funcion={(e) => setApellidoPaterno(e.target.value)}
                        errors={errors.apellido_paterno}
                        labelWidth="w-44"
                    />

                    {errors.apellido_paterno && Array.isArray(errors.apellido_paterno) && renderErrorMessages(errors.apellido_paterno)}

                    <InputForm
                        id="apellido_materno"
                        labelName="Apellido Materno:"
                        inputType="text"
                        value={apellidoMaterno}
                        funcion={(e) => setApellidoMaterno(e.target.value)}
                        errors={errors.apellido_materno}
                        labelWidth="w-44"
                    />

                    {errors.apellido_materno && Array.isArray(errors.apellido_materno) && renderErrorMessages(errors.apellido_materno)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='tipo_documento' className='w-44 flex items-center font-normal text-[#454545]'>Distrito: </label>
                        <Select options={optionsDistritos}
                            className='w-full z-50'
                            styles={getColourStyles(!!errors.distrito_id)}
                            id="tipo_documento"
                            value={distrito}
                            onChange={(option) => {setDistrito(option); console.log(option)}}
                            required
                            placeholder="Seleccionar distrito..."
                            noOptionsMessage={() => ""}
                            loadingMessage={() => "Cargando..."}
                        />
                    </div>

                    {errors.distrito_id && Array.isArray(errors.distrito_id) && renderErrorMessages(errors.distrito_id)}

                    <InputForm
                        id="direccion"
                        labelName="Dirección:"
                        inputType="text"
                        value={direccion}
                        funcion={(e) => setDireccion(e.target.value)}
                        errors={errors.direccion}
                        labelWidth="w-44"
                    />

                    {errors.direccion && Array.isArray(errors.direccion) && renderErrorMessages(errors.direccion)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='tipo_cliente' className='w-44 flex items-center font-normal text-[#454545]'>Tip. de Cliente: </label>
                        <Select options={optionsTipoClientes}
                            className='w-full'
                            styles={getColourStyles(!!errors.tipo_cliente_id)}
                            id="tipo_cliente"
                            value={tipoCliente}
                            onChange={(option) => setTipoCliente(option)}
                            required
                            placeholder="Seleccionar tipo del cliente..."
                            noOptionsMessage={() => "No hay resultados"}
                            loadingMessage={() => "Cargando..."}
                        />
                    </div>

                    {errors.tipo_cliente_id && Array.isArray(errors.tipo_cliente_id) && renderErrorMessages(errors.tipo_cliente_id)}

                    <InputForm
                        id="celular"
                        labelName="Celular:"
                        inputType="text"
                        value={celular}
                        funcion={(e) => setCelular(e.target.value.replace(/[^0-9]/g, ""))}
                        errors={errors.celular}
                        maxLength={9}
                        labelWidth="w-44"
                    />

                    {errors.celular && Array.isArray(errors.celular) && renderErrorMessages(errors.celular)}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-44
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewCliente}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Cliente
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        /* disabled={loadingVolverBtn} */
                        /* onClick={functionBtnVolverProv} */
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
