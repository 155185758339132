import { Routes, Route, HashRouter } from "react-router-dom";
import Login from "./pages/pagesAuth/Login";
import ProtectedRoutes from "./pages/pagesAuth/ProtectedRoutes";
import Dashboard from "./pages/pagesPublic/Dashboard";
import LayoutUsuario from "./layouts/LayoutUsuario";
import Perfil from "./pages/pagesPublic/user/Perfil";

import { ToastContainer } from 'react-toastify';
import UsersList from "./pages/pagesPublic/admin/UsersList";

import 'dayjs/locale/en-gb';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import RecuperarContrasenia from "./pages/pagesAuth/RecuperarContrasenia";
import ValidarCodigo from "./pages/pagesAuth/ValidarCodigo";
import CambiarContra from "./pages/pagesAuth/CambiarContra";
import ProveedoresList from "./pages/pagesPublic/admin/ProveedoresList";
import ClientesList from "./pages/pagesPublic/admin/Clientes/ClientesList";
import CategoriasInsumosList from "./pages/pagesPublic/user/CategoriaInsumos/CategoriasInsumosList";
import CategoriasProductosList from "./pages/pagesPublic/user/CategoriaProductos/CategoriasProductosList";
import InsumosList from "./pages/pagesPublic/user/Insumos/InsumosList";
import ProductosList from "./pages/pagesPublic/user/Productos/ProductosList";
import ServiciosList from "./pages/pagesPublic/user/Servicios/ServiciosList";
import IngresoInsumoList from "./pages/pagesPublic/user/MovimientoInsumos/IngresoInsumoList";
import SalidaInsumoList from "./pages/pagesPublic/user/MovimientoInsumos/SalidaInsumoList";
import IngresoProductoList from "./pages/pagesPublic/user/MovimientoProductos/IngresoProductoList";
import SalidaProductoList from "./pages/pagesPublic/user/MovimientoProductos/SalidaProductoList";
import IngresoServicioList from "./pages/pagesPublic/user/MovimientoServicios/IngresoServicioList";
import SalidaServicioList from "./pages/pagesPublic/user/MovimientoServicios/SalidaServicioList";
import MarcasInsumosList from "./pages/pagesPublic/user/MarcasInsumos/MarcasInsumosList";
import ServiciosEspecificosList from "./pages/pagesPublic/user/ServiciosEspecificos/ServiciosEspecificosList";
import KardexInsumoList from "./pages/pagesPublic/user/MovimientoInsumos/KardexInsumoList";
import KardexProductoList from "./pages/pagesPublic/user/MovimientoProductos/KardexProductoList";
import KardexServicioList from "./pages/pagesPublic/user/MovimientoServicios/KardexServicioList";
import NotFound404 from "./pages/pagesPublic/errorPages/NotFound404";
import NotFound404Protected from "./pages/pagesPublic/errorPages/NotFound404Protected";
import Contacto from "./pages/pagesPublic/user/Soporte/Contacto";

function App() {

  const theme = useTheme();

  return (

    <HashRouter>

      <ThemeProvider theme={createTheme(theme, esES)}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>

          <Routes>

            {/* 404 para todas las páginas */}
            <Route path="*" element={<NotFound404 />}></Route>

            <Route path="/" element={<Login />}></Route>
            <Route path="/recuperar-password" element={<RecuperarContrasenia />}></Route>
            <Route path="/validar-codigo" element={<ValidarCodigo />}></Route>
            <Route path="/cambiar-password" element={<CambiarContra />}></Route>
            <Route element={<ProtectedRoutes />}>

              {/* Intentar poner 404 para rutas protegidas */}
              <Route path="/usuario" element={<LayoutUsuario />}>
                <Route path="*" element={<NotFound404Protected />}></Route>
              </Route>


              <Route path="/usuario/dashboard" element={<LayoutUsuario />}>
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="/usuario/perfil" element={<LayoutUsuario />}>
                <Route index element={<Perfil />} />
              </Route>
              <Route path="/usuario/lista-usuarios" element={<LayoutUsuario />}>
                <Route index element={<UsersList />} />
              </Route>

              <Route path="/usuario/lista-proveedores" element={<LayoutUsuario />}>
                <Route index element={<ProveedoresList />} />
              </Route>

              {/* Rutas para Clientes */}

              <Route path="/usuario/lista-clientes" element={<LayoutUsuario />}>
                <Route index element={<ClientesList />} />
              </Route>

              {/* Ruta para Categorías de insumos */}
              <Route path="/usuario/lista-categorias-insumos" element={<LayoutUsuario />}>
                <Route index element={<CategoriasInsumosList />} />
              </Route>

              {/* Ruta para Categorías de productos */}
              <Route path="/usuario/lista-categorias-productos" element={<LayoutUsuario />}>
                <Route index element={<CategoriasProductosList />} />
              </Route>

              {/* Ruta para Marcas de insumos */}
              <Route path="/usuario/lista-marcas-insumos" element={<LayoutUsuario />}>
                <Route index element={<MarcasInsumosList />} />
              </Route>

              {/* Ruta para Servicios Específicos */}
              <Route path="/usuario/lista-servicios-especificos" element={<LayoutUsuario />}>
                <Route index element={<ServiciosEspecificosList />} />
              </Route>

              {/* Ruta para Insumos */}
              <Route path="/usuario/lista-insumos-principal" element={<LayoutUsuario />}>
                <Route index element={<InsumosList />} />
              </Route>

              {/* Ruta para Productos */}
              <Route path="/usuario/lista-productos-principal" element={<LayoutUsuario />}>
                <Route index element={<ProductosList />} />
              </Route>

              {/* Ruta para Servicios */}
              <Route path="/usuario/lista-servicios-principal" element={<LayoutUsuario />}>
                <Route index element={<ServiciosList />} />
              </Route>

              {/* Ruta para Ingreso de Insumos */}
              <Route path="/usuario/lista-insumos-ingresos" element={<LayoutUsuario />}>
                <Route index element={<IngresoInsumoList />} />
              </Route>

              {/* Ruta para Salida de Insumos */}
              <Route path="/usuario/lista-insumos-salidas" element={<LayoutUsuario />}>
                <Route index element={<SalidaInsumoList />} />
              </Route>

              {/* Ruta para Ingreso de Productos */}
              <Route path="/usuario/lista-productos-ingresos" element={<LayoutUsuario />}>
                <Route index element={<IngresoProductoList />} />
              </Route>

              {/* Ruta para Salida de Productos */}
              <Route path="/usuario/lista-productos-salidas" element={<LayoutUsuario />}>
                <Route index element={<SalidaProductoList />} />
              </Route>

              {/* Ruta para Ingreso de Servicios */}
              <Route path="/usuario/lista-servicios-ingresos" element={<LayoutUsuario />}>
                <Route index element={<IngresoServicioList />} />
              </Route>

              {/* Ruta para Salida de Servicios */}
              <Route path="/usuario/lista-servicios-salidas" element={<LayoutUsuario />}>
                <Route index element={<SalidaServicioList />} />
              </Route>

              {/* Ruta para Kardex de Insumos */}
              <Route path="/usuario/lista-insumos-kardex" element={<LayoutUsuario />}>
                <Route index element={<KardexInsumoList />} />
              </Route>

              {/* Ruta para Kardex de Productos */}
              <Route path="/usuario/lista-productos-kardex" element={<LayoutUsuario />}>
                <Route index element={<KardexProductoList />} />
              </Route>

              {/* Ruta para Kardex de Productos */}
              <Route path="/usuario/lista-servicios-kardex" element={<LayoutUsuario />}>
                <Route index element={<KardexServicioList />} />
              </Route>

              {/* Ruta para Kardex de Productos */}
              <Route path="/usuario/soporte-contacto" element={<LayoutUsuario />}>
                <Route index element={<Contacto />} />
              </Route>

            </Route>
          </Routes>

          <ToastContainer />
        </LocalizationProvider>
      </ThemeProvider>

    </HashRouter>

  );
}

export default App;
