import React, { useMemo, useState, useEffect } from 'react'

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';
import Config from '../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
/* import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import ModalAnadirMarcaInsumo from '../forms/MarcasInsumos/ModalAnadirMarcaInsumo';
import ModalEditarMarcaInsumo from '../forms/MarcasInsumos/ModalEditarMarcaInsumo';

export default function MarcasInsumosTable() {

  const { getRol } = AuthUser();

  const [marcasInsumos, setMarcasInsumos] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [rowCount, setRowCount] = useState(0);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');

  //Para actualizar luego de agregar o editar
  const updateMarcasInsumosList = async () => {
    await getMarcasInsumosAll();
  };

  useEffect(() => {
    getMarcasInsumosAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting
  ]);

  const getMarcasInsumosAll = async () => {
    setTableLoading(true);

    let getCatInsumosList;

    if (getRol() === "Administrador") {
      getCatInsumosList = Config.getMarcasInsumosListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    } else if (getRol() === "Operario") {
      getCatInsumosList = Config.getMarcasInsumosListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    }

    try {
      const response = await getCatInsumosList;
      console.log(response);
      setMarcasInsumos(response.data.data);
      setRowCount(response.data.total);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const deleteOneMarcaInsumo = async (id) => {
    setTableLoading(true);
    let deleteMarcaCatInsumo;

    if (getRol() == "Administrador") {
      deleteMarcaCatInsumo = Config.deleteMarcaInsumoAdmin(id);
    }

    try {
      const response = await deleteMarcaCatInsumo;
      console.log(response);
      await getMarcasInsumosAll();
      return { status: response.status, message: response.data.message };
    } catch (error) {
      await getMarcasInsumosAll();
      console.error("Error:", error);
      if (error.response) {
        return { status: error.response.status, message: error.response.data.message };
      } else {
        return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
      }
    } finally {
      setTableLoading(false);
    }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //SweetAlert2
  const showSwal = async (id) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar esta marca de insumo?",
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#71717a",
      confirmButtonText: "Sí, eliminar"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          let mensaje = await deleteOneMarcaInsumo(id);

          let title = "";
          let icon = "";
          let text = mensaje.message;

          console.log(mensaje);

          if (mensaje.status === 200) {
            title = "¡Eliminado!";
            icon = "success";
          } else {
            title = "Error";
            icon = "error";
          }

          notify(text, icon);
        } catch (error) {
          console.error("Error eliminando la marca de insumo:", error);
          setTableLoading(false);
          notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde.`, "error");
        }
      }
    });
  }

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      //{
      //    accessorKey: "id", //simple recommended way to define a column
      //    header: "ID",
      //    size: 10
      /* muiTableHeadCellProps: { sx: { color: "green" } }, //custom props
      Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render */
      //},
      {
        id: "#",
        header: "Nro.",
        size: 80,
        grow: false,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => row.nombre_marca_insumo,
        id: "nombre_marca_insumo",
        header: "Nombre de marca",
        size: 200,
        grow: false,
      },
    ],
    []
  );

  const data = (marcasInsumos ? marcasInsumos : []);

  const table = useMaterialReactTable({
    columns,
    data,

    enableColumnResizing: true,
    enableColumnPinning: true,
    columnResizeMode: 'onEnd',
    layoutMode: 'grid',

    defaultColumn: {
      maxSize: 9000,
      minSize: 50,
      size: 50, //default size is usually 180
    },

    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: { sx: { maxHeight: '600px' } },

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {
        // direccion: false,
        // fecha_registro: false,
        // fecha_editado: false,
        // telefono: false
      }
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todos las marcas de insumos',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton title='Editar' onClick={() => {
          setEditId(row.original.id_marcainsumo);
          setOpenEdit(true);
          /* console.log("fila", row.original.id); */
        }} color="primary">
          <EditIcon />
        </IconButton>

        {/* Eliminar */}

        {getRol() == "Administrador" ?
          <IconButton title='Eliminar' onClick={() => {
            showSwal(row.original.id_marcainsumo);
            /* console.log("fila id: ", row.original.id); */
          }}
            color="error">
            <DeleteIcon />
          </IconButton>
          : null}
      </Box>
    ),
  });

  return (
    <div>

      <div className='flex justify-start mb-5 flex-wrap'>
        <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
          onClick={() => { setOpenAdd(true); console.log(openAdd); }}
        >
          <FaPlus className='me-3' />
          Añadir Marca de Insumo
        </button>
      </div>

      <MaterialReactTable
        table={table}
      />

      <ModalAnadirMarcaInsumo
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        updateMarcasInsumosList={updateMarcasInsumosList}
      />

      <ModalEditarMarcaInsumo
        open={openEdit}
        editId={editId}
        onClose={() => setOpenEdit(false)}
        updateMarcasInsumosList={updateMarcasInsumosList}
      />

    </div>
  )
}
