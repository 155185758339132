import React, { useEffect, useState, useRef } from 'react';

import Config from '../../../../Config';
import AuthUser from '../../../../pages/pagesAuth/AuthUser';
import InputForm from '../../../inputs/InputForm';

import Modal from '../../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarIngresoServicio({ open, onClose, updateServicioList, editId }) {

    const { getRol, getToken } = AuthUser();

    const id = editId;

    const [userToken, setUserToken] = useState('');
    const [servicioId, setServicioId] = useState('');
    const [cantidad, setCantidad] = useState('');

    const [usuarioResponsableMermaId, setUsuarioResponsableMermaId] = useState('');
    const [cantidadMerma, setCantidadMerma] = useState('');

    const [precioUnitario, setPrecioUnitario] = useState('');
    const [fechaIngreso, setFechaIngreso] = useState('');

    // Función para formatear la fecha
    const formatFechaForDB = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = "00";
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //Variable para restringir fecha
    const now = new Date();
    const now_servicio = formatFechaForDB(now);

    //Mostrar datos actuales de ingreso, salida y saldo
    const [labelIngreso, setLabelIngreso] = useState('');
    const [labelCantDefectuosa, setLabelCantDefectuosa] = useState('');
    const [labelSalida, setLabelSalida] = useState('');
    const [labelSaldo, setLabelSaldo] = useState('');

    //Limpiar async select
    const selectInputRef = useRef();
    const [limpiarAsync, setLimpiarAsync] = useState(false);

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarIngresoServicio();
            setUserToken(getToken());
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadServiciosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectServiciosAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectServiciosOperario(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectServiciosAlmacenero(globalFilter);
            }

            const arrayServicios = response.data;
            if (arrayServicios.exito == 0) {
                return [];
            }

            return arrayServicios.map(servicio => ({
                value: servicio.id,
                label: `${servicio.id} - ${servicio.codigo} - ${servicio.servicio_especifico?.nombre_servicio}`,
                ingreso: servicio.ingreso,
                merma: servicio.merma,
                salida: servicio.salida,
                saldo: servicio.saldo,
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de servicios. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadUsuarioResponsableOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectServicioResponsableMermaAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectServicioResponsableMermaOperario(globalFilter);
            } else if (getRol() === "Almacenero") {
                response = await Config.getSelectServicioResponsableMermaAlmacenero(globalFilter);
            }

            console.log(response);

            const arrayUsuariosResponsable = response.data;
            if (arrayUsuariosResponsable.exito == 0) {
                return [];
            }

            return arrayUsuariosResponsable.map(usuario_responsable => ({
                value: usuario_responsable.id,
                label: `${usuario_responsable.name} | ${usuario_responsable.role.rol} | ${usuario_responsable.email}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de usuarios. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarIngresoServicio = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneIngresoServicioAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneIngresoServicioOperario(id);
            }

            console.log(response);

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {

                setServicioId(response.data.servicio);
                setCantidad(response.data.cantidad_ingreso_serv);

                setUsuarioResponsableMermaId(response.data?.responsable_merma);
                setCantidadMerma(response.data.merma_servicios == 0 ? '' : response.data.merma_servicios);

                setPrecioUnitario(response.data.precio_unitario_serv);
                setFechaIngreso(response.data.fecha_ingreso_serv);

                setLabelIngreso(response.data.servicio.ingreso);
                setLabelCantDefectuosa(response.data.servicio.merma);
                setLabelSalida(response.data.servicio.salida);
                setLabelSaldo(response.data.servicio.saldo);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {
            const ingresoServicioData = {
                token_usuario: userToken,
                servicio_id: (servicioId.id ? servicioId.id : servicioId.value),
                merma_servicios: cantidadMerma === '' ? null : cantidadMerma,
                responsable_merma_id: usuarioResponsableMermaId == null ? setUsuarioResponsableMermaId('') : (usuarioResponsableMermaId.id ? usuarioResponsableMermaId.id :usuarioResponsableMermaId.value),
                cantidad_ingreso_serv: cantidad,
                precio_unitario_serv: precioUnitario,
                fecha_ingreso_serv: fechaIngreso
            };

            console.log("Datos antes de editar: ", ingresoServicioData);


            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateIngresoServicioAdmin(id, ingresoServicioData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateIngresoServicioOperario(id, ingresoServicioData);
            }

            console.log(response);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El ingreso de servicio ha sido editado correctamente.", "success");
                    setUserToken('');
                    setServicioId('');
                    setCantidad('');
                    setCantidadMerma('');
                    setPrecioUnitario('');
                    setFechaIngreso('');
                    onClose();
                    updateServicioList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.error(error);
            console.log(error);
            updateServicioList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '155px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Ingreso Servicio</h3>

                <form key="form-select">

                    {!loadingData ?

                        <>

                            {errors.token_usuario && Array.isArray(errors.token_usuario) && renderErrorMessages(errors.token_usuario)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Servicio: </label>
                                <AsyncSelect
                                    key="servicio-select"
                                    inputkey="servicio-select"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadServiciosOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.servicio_id)}
                                    id="servicio_id"
                                    /* value={servicioId} */
                                    defaultValue={{
                                        id: servicioId.id,
                                        label: `${servicioId.id} - ${servicioId.codigo} - ${servicioId.servicio_especifico?.nombre_servicio}`
                                    }}
                                    onChange={(option, id) => {
                                        setLabelIngreso('');
                                        setLabelCantDefectuosa('');
                                        setLabelSalida('');
                                        setLabelSaldo('');

                                        setServicioId(option);
                                        console.log(option, id);
                                        setLabelIngreso(option.ingreso);
                                        setLabelCantDefectuosa(option.merma);
                                        setLabelSalida(option.salida);
                                        setLabelSaldo(option.saldo);
                                    }}
                                    placeholder="Seleccionar servicio..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.servicio_id && Array.isArray(errors.servicio_id) && renderErrorMessages(errors.servicio_id)}

                            <InputForm
                                key="cantidad-select"
                                inputkey="cantidad-select"
                                id="cantidad"
                                labelName="Cantidad:"
                                inputType="number"
                                value={cantidad}
                                funcion={(e) => setCantidad(e.target.value)}
                                errors={errors.cantidad_ingreso_serv}
                                labelWidth="w-56"
                            />

                            {errors.cantidad_ingreso_serv && Array.isArray(errors.cantidad_ingreso_serv) && renderErrorMessages(errors.cantidad_ingreso_serv)}

                            <InputForm
                                key="merma-select"
                                inputkey={"merma-select"}
                                id="merma_servicios"
                                labelName="Merma:"
                                inputType="text"
                                value={cantidadMerma ? cantidadMerma : ''}
                                funcion={(e) => setCantidadMerma(e.target.value.replace(/[^0-9]/g, ""))}
                                onKeyUp={(e) => {
                                    if(cantidadMerma==0 || cantidadMerma=='' || cantidadMerma==null){
                                        setUsuarioResponsableMermaId('');
                                        setLimpiarAsync(true);
                                        selectInputRef.current.clearValue();
                                        console.log("Data del user respon: ",usuarioResponsableMermaId);
                                    }else{
                                        setLimpiarAsync(false);
                                    }
                                }}
                                errors={errors.merma_servicios}
                                labelWidth="w-56"
                            />

                            {errors.merma_servicios && Array.isArray(errors.merma_servicios) && renderErrorMessages(errors.merma_servicios)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Responsable M.: </label>
                                <AsyncSelect
                                    key="responsable-select"
                                    inputkey="responsable-select"
                                    ref={selectInputRef}
                                    //isClearable={true}
                                    escapeClearsValue={true}
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadUsuarioResponsableOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.responsable_merma_id)}
                                    id="responsable_merma_id"
                                    /* value={usuarioResponsableMerma} */
                                    defaultValue={usuarioResponsableMermaId ? {
                                        id: usuarioResponsableMermaId.id,
                                        label: `${usuarioResponsableMermaId.name} | ${usuarioResponsableMermaId.role?.rol} | ${usuarioResponsableMermaId.email}`
                                    } : null}
                                    onChange={(option, id) => {
                                        setUsuarioResponsableMermaId(option);
                                        console.log(option, id);
                                    }}
                                    placeholder="Seleccionar responsable de merma..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.responsable_merma_id && Array.isArray(errors.responsable_merma_id) && renderErrorMessages(errors.responsable_merma_id)}

                            <InputForm
                                key="precio-select"
                                inputkey={"precio-select"}
                                id="precio_unitario_serv"
                                labelName="Precio Unitario:"
                                inputType="text"
                                value={precioUnitario}
                                funcion={(e) => setPrecioUnitario(e.target.value)}
                                errors={errors.precio_unitario_serv}
                                labelWidth="w-56"
                            />

                            {errors.precio_unitario_serv && Array.isArray(errors.precio_unitario_serv) && renderErrorMessages(errors.precio_unitario_serv)}

                            {/* FECHA DE INGRESO */}

                            <div className='flex mb-3'>

                                <label htmlFor="date_picker" className={`w-56 flex items-center font-normal text-[#454545]`} style={{ width: "158px" }}>Fecha de Ingreso:</label>
                                <input
                                    key="fecha-select"
                                    inputkey="fecha-select"
                                    value={fechaIngreso || ""}
                                    onChange={(e) => { setFechaIngreso(formatFechaForDB(e.target.value)); console.log(formatFechaForDB(e.target.value)) }}

                                    type="datetime-local"
                                    max={now_servicio}
                                    className={`px-2 py-1 bg-[#F9F9F9] border border-[#D9D9D9] 
    rounded-md focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B] 
    ${errors.fecha_ingreso_serv ? 'border-red-500' : 'border-slate-300'}`}
                                />

                            </div>

                            {errors.fecha_ingreso_serv && Array.isArray(errors.fecha_ingreso_serv) && renderErrorMessages(errors.fecha_ingreso_serv)}

                            <h4 className='text-sm mb-3 text-gray-600'>Datos del servicio seleccionado: </h4>

                            <div className='flex justify-between flex-wrap'>

                                <InputForm
                                    key="ingreso-select"
                                    inputkey="ingreso-select"
                                    id="ingreso"
                                    labelName="Ingreso:"
                                    inputType="number"
                                    value={labelIngreso}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    key="defectuosa-select"
                                    inputkey="defectuosa-select"
                                    id="cant_defectuosa"
                                    labelName="Merma:"
                                    inputType="number"
                                    value={labelCantDefectuosa}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    key="salida-select"
                                    inputkey="salida-select"
                                    id="salida"
                                    labelName="Salida:"
                                    inputType="number"
                                    value={labelSalida}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                                <InputForm
                                    key="saldo-select"
                                    inputkey="saldo-select"
                                    id="saldo"
                                    labelName="Saldo:"
                                    inputType="number"
                                    value={labelSaldo}
                                    /* funcion={(e) => setCantidad(e.target.value)} */
                                    labelWidth="w-16"
                                    isDisabled={true}
                                    bgColor='bg-[#e0e0e0]'
                                    inputWidth='w-32'
                                />

                            </div>

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Ingreso Servicio
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
