import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';
import TextAreaForm from '../../inputs/TextAreaForm';

import AsyncSelect from 'react-select/async';

export default function ModalAnadirServicio({ open, onClose, updateServicioList }) {

    const { getRol } = AuthUser();

    const [clienteId, setClienteId] = useState('');
    const [servicioEspecificoId, setServicioEspecificoId] = useState('');
    //const [nombreServicio, setNombreServicio] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const loadClientesOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectClientesAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectClientesOperario(globalFilter);
            }
            const arrayClientes = response.data;
            if(arrayClientes.exito == 0){
                return [];
            }
                        
            return arrayClientes.map(cliente => ({
                value: cliente.id,
                label: `${cliente.id} - ${cliente.nombres} ${cliente.apellido_paterno} ${cliente.apellido_materno} - ${cliente.nro_documento}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de clientes. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadServiciosEspecificosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectServiciosEspecificoAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectServiciosEspecificoOperario(globalFilter);
            }
            const arrayServiciosEspecificos = response.data;
            if(arrayServiciosEspecificos.exito == 0){
                return [];
            }
                        
            return arrayServiciosEspecificos.map(servicio_especifico => ({
                value: servicio_especifico.id,
                label: `${servicio_especifico.id} - ${servicio_especifico.nombre_servicio}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de servicios específicos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewServicio = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const servicioData = {
            cliente_id: clienteId.value,
            servicio_especifico_id: servicioEspecificoId.value,
            descripcion: descripcion
        };

        console.log("Data del servicio: ", servicioData);

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarServicioAdmin(servicioData);
            }else if(getRol() === "Operario"){
                response = await Config.postGuardarServicioOperario(servicioData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El servicio ha sido añadido correctamente.", "success");
                    setClienteId('');
                    setServicioEspecificoId('');
                    setDescripcion('');
                    onClose();
                    updateServicioList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateServicioList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '162px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Servicio</h3>

                <form>

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Cliente: </label>
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            loadOptions={loadClientesOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.cliente_id)}
                            id="cliente_id"
                            value={clienteId}
                            onChange={(option, id) => { setClienteId(option); console.log(option, id); }}
                            placeholder="Seleccionar cliente..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    {errors.cliente_id && Array.isArray(errors.cliente_id) && renderErrorMessages(errors.cliente_id)}

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='servicio_especifico_id' className='w-56 flex items-center font-normal text-[#454545]'>Servicio: </label>
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            loadOptions={loadServiciosEspecificosOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.servicio_especifico_id)}
                            id="servicio_especifico_id"
                            value={servicioEspecificoId}
                            onChange={(option, id) => { setServicioEspecificoId(option); console.log(option, id); }}
                            placeholder="Seleccionar servicio específico..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                        />
                    </div>

                    {errors.servicio_especifico_id && Array.isArray(errors.servicio_especifico_id) && renderErrorMessages(errors.servicio_especifico_id)}

                    <TextAreaForm
                        id="descripcion"
                        labelNameArea="Descripción:"
                        value={descripcion}
                        row={4}
                        cols={40}
                        funcion={(e) => setDescripcion(e.target.value)}
                        errors={errors.descripcion}
                        labelWidth="w-56"
                    />

                    {errors.descripcion && Array.isArray(errors.descripcion) && renderErrorMessages(errors.descripcion)}


                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-44
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewServicio}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Servicio
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
