import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { MRT_Localization_ES } from 'material-react-table/locales/es/index.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import AuthUser from '../../pages/pagesAuth/AuthUser';
import Config from '../../Config';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';

import { FaPlus, FaFileCsv } from 'react-icons/fa';
import CsvDownloader from 'react-csv-downloader';
import UserPdf from '../createPdf/UserPdf';

/* import ModalAnadirCliente from '../forms/Cliente/ModalAnadirCliente';
import ModalEditarCliente from '../forms/Cliente/ModalEditarCliente'; */

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

import { FaCircle } from "react-icons/fa";
import ModalAnadirProducto from '../forms/Producto/ModalAnadirProducto';
import ModalEditarProducto from '../forms/Producto/ModalEditarProducto';
import ProductosPdf from '../createPdf/Productos/ProductosPdf';
import ProductosExcel from '../createExcel/Productos/ProductosExcel';

export default function ProductosTable() {

  const { getRol } = AuthUser();

  const [productos, setProductos] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(0);

  //Modal
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editId, setEditId] = useState('');

  //Para actualizar luego de agregar o editar
  const updateProductoList = async () => {
    await getProductosAll();
  };

  useEffect(() => {
    getProductosAll();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    globalFilter,
    sorting
  ]);

  const getProductosAll = async () => {
    setTableLoading(true);

    let getProductosList;

    if (getRol() === "Administrador") {
      getProductosList = Config.getProductosListAdmin(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    } else if (getRol() === "Operario") {
      getProductosList = Config.getProductosListOperario(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter,
        columnFilters,
        sorting
      );
    }

    try {
      const response = await getProductosList;
      if (response.data.exito == '0') {
        notify(response.data.message, 'error')
      } else {
        console.log(response);
        setProductos(response.data.data);
        setRowCount(response.data.total);
        //setClientes(response.data);
        /* console.log(response); */
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTableLoading(false);
    }
  }

  const deleteOneProducto = async (id) => {
      setTableLoading(true);
      let deleteOneProducto;

      if (getRol() == "Administrador") {
        deleteOneProducto = Config.deleteOneProductoAdmin(id);
      }

      //EL DELETE NO ES PARA OPERARIO, SÓLO PARA ADMINISTRADOR

      try {
          const response = await deleteOneProducto;
          console.log(response);
          await getProductosAll();
          return { status: response.status, message: response.data.message };
      } catch (error) {
          await getProductosAll();
          console.error("Error:", error);
          if (error.response) {
              return { status: error.response.status, message: error.response.data.message };
          } else {
              return { status: 500, message: `Error desconocido. Vuelva a intentarlo.` };
          }
      } finally {
          setTableLoading(false);
      }
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //SweetAlert2
  const showSwal = async (id) => {
      Swal.fire({
          title: "¿Estás seguro de eliminar este producto?",
          text: "¡No podrás revertir esta acción!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#dc2626",
          cancelButtonColor: "#71717a",
          confirmButtonText: "Sí, eliminar"
      }).then(async (result) => {
          if (result.isConfirmed) {

              try {
                  let mensaje = await deleteOneProducto(id);

                  let title = "";
                  let icon = "";
                  let text = mensaje.message;

                  console.log(mensaje);

                  if (mensaje.status === 200) {
                      title = "¡Eliminado!";
                      icon = "success";
                  } else {
                      title = "Error";
                      icon = "error";
                  }

                  notify(text, icon);
              } catch (error) {
                  console.error("Error eliminando el producto:", error);
                  setTableLoading(false);
                  notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
              }
          }
      });
  }

  // COLUMNAS DE LA TABLA

  const columns = useMemo(
    () => [
      {
        id: "#",
        header: "Nro.",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        }
      },
      {
        accessorFn: (row) => (row.categoria_producto ? row.categoria_producto.nombre_categoria_producto : '-'),
        id: "nombre_categoria_producto",
        header: "Categoría",
        size: 5
      },
      {
        accessorFn: (row) => row.nombre_producto,
        id: "nombre_producto",
        header: "Producto",
        size: 5
      },
      {
        accessorFn: (row) => row.codigo,
        id: "codigo",
        header: "Código",
        size: 1,
      },
      {
        accessorFn: (row) => row.color,
        id: "color",
        header: "Color",
        size: 1,
      },
      {
        accessorFn: (row) => row.talla,
        id: "talla",
        header: "Talla",
        size: 1,
      },
      {
        accessorFn: (row) => row.ingreso,
        id: "ingreso",
        header: "Ingreso",
        size: 1,
      },
      {
        accessorFn: (row) => row.cant_defectuosa,
        id: "cant_defectuosa",
        header: "Cant. Defectuosa",
        size: 2
      },
      {
        accessorFn: (row) => row.salida,
        id: "salida",
        header: "Salida",
        size: 2,
      },
      {
        accessorFn: (row) => row.saldo,
        id: "saldo",
        header: "Saldo",
        size: 2,
      },
      {
        accessorFn: (row) => row.estado,
        id: "estado",
        header: "Estado",
        size: 10,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              fontSize: "10px",
              color: `${cell.getValue() == '1' ? 'green' : '#d63333'}`,
            })}
          >
            <FaCircle />
          </Box>
        ),
      },
    ],
    []
  );

  const data = (productos ? productos : []);

  const table = useMaterialReactTable({
    columns,
    data,

    enableExpandAll: false, //disable expand all button
    /* muiDetailPanelProps: () => ({
        sx: (theme) => ({
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? 'rgba(255,210,244,0.1)'
                    : 'rgba(0,0,0,0.1)',
        }),
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
        sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
        },
    }),

    renderDetailPanel: ({ row }) =>
        row.original.direccion ? (
          <Box
            sx={{
              display: '',
              margin: 'auto',
              gridTemplateColumns: '1fr 1fr',
              width: '100%',
            }}
          >
            <Typography><span className='font-bold'>Dirección:</span> {row.original.direccion}</Typography>
            <Typography><span className='font-bold'>Fecha de registro:</span> {row.original.fecha_registro}</Typography>
            <Typography><span className='font-bold'>Fecha de edición:</span> {row.original.fecha_editado}</Typography>
            <Typography><span className='font-bold'>Teléfono:</span> {row.original.telefono}</Typography>
          </Box>
        ) : null, */

    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnVisibility: {
        direccion: false,
        fecha_registro: false,
        fecha_editado: false,
        telefono: false
      }
    },

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,

    state: {
      columnFilters,
      globalFilter,
      isLoading: tableLoading,
      pagination,
      sorting,
    },

    localization: MRT_Localization_ES,
    muiSearchTextFieldProps: {
      placeholder: 'Buscar todos los productos',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton title='Editar' onClick={() => {
          setEditId(row.original.producto_id);
          setOpenEdit(true);
          console.log("fila", row.original.producto_id);
        }} color="primary">
          <EditIcon />
        </IconButton>

        {getRol() == "Administrador" ?
          <IconButton title='Eliminar' onClick={() => {
            showSwal(row.original.producto_id);
            /* console.log("fila id: ", row.original.id); */
          }}
            color="error">
            <DeleteIcon />
          </IconButton>
          : null}

      </Box>
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px' }}>

          {getRol() === "Administrador" || getRol() === "Gerente" ?

            <>
              <ProductosExcel productosData={productos} />
              <ProductosPdf productosData={productos} />
            </>

            : null}

        </Box>
      </Box>
    ),

  });

  return (
    <div>

      <div className='flex justify-start mb-5 flex-wrap'>
        <button className='bg-[#D27313] hover:bg-[#cb6f13] rounded shadow-md text-white p-3 flex items-center'
          onClick={() => { setOpenAdd(true); console.log(openAdd); }}
        >
          <FaPlus className='me-3' />
          Añadir Producto
        </button>
      </div>

      <div className='mb-20'>

        <MaterialReactTable
          table={table}
        />

      </div>

      <ModalAnadirProducto
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        updateProductoList={updateProductoList}
      />

      <ModalEditarProducto
        open={openEdit}
        editId={editId}
        onClose={() => setOpenEdit(false)}
        updateProductoList={updateProductoList}
      />

    </div>
  )
}
