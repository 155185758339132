import React, { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

import AuthUser from '../../../pages/pagesAuth/AuthUser';
import Modal from '../../modals/Modal';

import { FaPlus } from "react-icons/fa";
import Config from '../../../Config';

import { toast } from 'react-toastify';
import InputForm from '../../inputs/InputForm';

import AsyncSelect from 'react-select/async';

export default function ModalAnadirProducto({ open, onClose, updateProductoList }) {

    const { getRol } = AuthUser();

    const [categoriaProductoId, setCategoriaProductoId] = useState('');
    const [nombreProducto, setNombreProducto] = useState('');
    const [color, setColor] = useState('');
    const [talla, setTalla] = useState('');

    const [errors, setErrors] = useState({});
    const [loadingSubmit, setloadingSubmit] = useState(false);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            setErrors({});
        };
    }, [open]);

    const loadCategoriasProductosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectCategoriaProductosAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectCategoriaProductosOperario(globalFilter);
            }
            const arrayCatProductos = response.data;
            if(arrayCatProductos.exito == 0){
                return [];
            }

            return arrayCatProductos.map(categoria_producto => ({
                value: categoria_producto.id_catproducto,
                label: `${categoria_producto.id_catproducto} - ${categoria_producto.nombre_categoria_producto}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar las categorías de productos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const addNewProducto = async (e) => {
        e.preventDefault();
        setloadingSubmit(true);
        setErrors({});

        const productoData = {
            categoria_producto_id: categoriaProductoId.value,
            nombre_producto: nombreProducto,
            color: color,            
            talla: talla,
        };

        try {
            let response;

            if (getRol() === "Administrador") {
                response = await Config.postGuardarProductoAdmin(productoData);
            }else if(getRol() === "Operario"){
                response = await Config.postGuardarProductoOperario(productoData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(response.data.message, "error");
                } else if (response.data.exito == 1) {
                    notify("El producto ha sido añadido correctamente.", "success");
                    setCategoriaProductoId('');
                    setNombreProducto('');
                    setColor('');
                    setTalla('');
                    onClose();
                    updateProductoList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }

        } catch (error) {
            console.error("Error: ", error);
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
            updateProductoList();
        } finally {
            setloadingSubmit(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div key={index} style={{ marginLeft: '176px' }}>
                <p className="text-red-500 text-sm -mt-3 mb-3">{msg}</p>
            </div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    }

  return (
    <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Añadir</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Añadir Producto</h3>

                <form>

                    <div className='flex mb-3 w-full'>
                        <label htmlFor='categoria_id' className='w-64 flex items-center font-normal text-[#454545]'>Categoría de Producto: </label>
                        <AsyncSelect 
                            cacheOptions
                            defaultOptions
                            loadOptions={loadCategoriasProductosOptions}
                            className={`w-full`}
                            styles={getColourStyles(!!errors.categoria_producto_id)}
                            id="categoria_id"
                            value={categoriaProductoId}
                            onChange={(option, id) => { setCategoriaProductoId(option); console.log(option, id); }}
                            placeholder="Seleccionar categoría de insumo..."
                            noOptionsMessage={() => "No se encontraron resultados"}
                            loadingMessage={() => "Cargando..."}
                        />
                    </div>

                    {errors.categoria_producto_id && Array.isArray(errors.categoria_producto_id) && renderErrorMessages(errors.categoria_producto_id)}

                    <InputForm
                        id="producto"
                        labelName="Nombre del Producto:"
                        inputType="text"
                        value={nombreProducto}
                        funcion={(e) => setNombreProducto(e.target.value)}
                        errors={errors.nombre_producto}
                        labelWidth="w-64"
                    />

                    {errors.nombre_producto && Array.isArray(errors.nombre_producto) && renderErrorMessages(errors.nombre_producto)}

                    <InputForm
                        id="color"
                        labelName="Color:"
                        inputType="text"
                        value={color}
                        funcion={(e) => setColor(e.target.value)}
                        errors={errors.color}
                        labelWidth="w-64"
                    />

                    {errors.color && Array.isArray(errors.color) && renderErrorMessages(errors.color)}

                    <InputForm
                        id="talla"
                        labelName="Talla:"
                        value={talla}
                        funcion={(e) => setTalla(e.target.value)}
                        errors={errors.talla}
                        labelWidth="w-64"
                    />

                    {errors.talla && Array.isArray(errors.talla) && renderErrorMessages(errors.talla)}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingSubmit}
                            className={`flex items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-44
                            text-white bg-[#EB8927]
                            me-3
                            max-[439px]:me-0
                            max-[469px]:m-0
                            ${loadingSubmit ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={addNewProducto}
                            type="submit">

                            {loadingSubmit ?
                                <div className='flex items-center justify-center w-44'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <FaPlus /> Guardar Producto
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            max-[426px]:mt-3
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
  )
}
