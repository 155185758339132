import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';
import TextAreaForm from '../../inputs/TextAreaForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

import AsyncSelect from 'react-select/async';

export default function ModalEditarServicio({ open, onClose, updateServicioList, editId }) {

    const { getRol } = AuthUser();

    const id = editId;

    const [clienteId, setClienteId] = useState('');
    const [servicioEspecificoId, setServicioEspecificoId] = useState('');
    // const [nombreServicio, setNombreServicio] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const [errors, setErrors] = useState({});

    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    const [zIndex, setZIndex] = useState(-1);

    useEffect(() => {

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
            cargarServicio();
            setZIndex(9999);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            setZIndex(-1);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const loadClientesOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectClientesAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectClientesOperario(globalFilter);
            }
            const arrayClientes = response.data;
            if (arrayClientes.exito == 0) {
                return [];
            }

            return arrayClientes.map(cliente => ({
                value: cliente.id,
                label: `${cliente.id} - ${cliente.nombres} ${cliente.apellido_paterno} ${cliente.apellido_materno} - ${cliente.nro_documento}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista ded clientes. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const loadServiciosEspecificosOptions = async (globalFilter) => {
        try {
            let response;
            if (getRol() === "Administrador") {
                response = await Config.getSelectServiciosEspecificoAdmin(globalFilter);
            } else if (getRol() === "Operario") {
                response = await Config.getSelectServiciosEspecificoOperario(globalFilter);
            }
            const arrayServiciosEspecificos = response.data;
            if (arrayServiciosEspecificos.exito == 0) {
                return [];
            }

            return arrayServiciosEspecificos.map(servicio_especifico => ({
                value: servicio_especifico.id,
                label: `${servicio_especifico.id} - ${servicio_especifico.nombre_servicio}`
            }));
        } catch (error) {
            console.error("Error:", error);
            notify("Ha ocurrido un error al cargar la lista de servicios específicos. Vuelva a intentarlo más tarde.", "error");
            return [];
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest(".modal-content") === null) {
            onClose();
        }
    };

    const cargarServicio = async () => {
        setLoadingData(true);
        setErrors({});

        try {

            let response;

            if (getRol() === "Administrador") {
                response = await Config.getOneServicioAdmin(id);
            } else if (getRol() === "Operario") {
                response = await Config.getOneServicioOperario(id);
            }

            if (response.data.exito == 0) {
                notify(`Error: ${response.data.message}`, "error");
            } else {
                setClienteId(response.data.cliente);
                setServicioEspecificoId(response.data.servicio_especifico);
                // setNombreServicio(response.data.nombre_servicio);
                setDescripcion(response.data.descripcion);
            }

        } catch (error) {
            console.error(error);
            notify(`Ha ocurrido un error. Vuelva a intentarlo más tarde. ${error}`, "error");
        } finally {
            setLoadingData(false);
        };
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoadingButton(true);

        try {

            const servicioData = {
                cliente_id: (clienteId.id ? clienteId.id : clienteId.value),
                servicio_especifico_id: (servicioEspecificoId.id ? servicioEspecificoId.id : servicioEspecificoId.value),
                // nombre_servicio: nombreServicio,
                descripcion: descripcion,
            };

            let response;

            if (getRol() === "Administrador") {
                response = await Config.putUpdateServicioAdmin(id, servicioData);
            } else if (getRol() === "Operario") {
                response = await Config.putUpdateServicioOperario(id, servicioData);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                if (response.data.exito == 0) {
                    notify(`Error: ${response.data.message}`, "error");
                } else if (response.data.exito == 1) {
                    notify("El servicio ha sido editado correctamente.", "success");
                    setClienteId('');
                    setServicioEspecificoId('');
                    /* setNombreServicio(''); */
                    setDescripcion('');
                    onClose();
                    updateServicioList();
                } else {
                    notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
                }
            }


        } catch (error) {
            console.log(error);
            updateServicioList();
            notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        } finally {
            setLoadingButton(false);
        };
    }

    const renderErrorMessages = (messages) => {
        return messages.map((msg, index) => (
            <div style={{ marginLeft: '136px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
        ));
    };

    const notify = (message, type) => {
        toast[type](message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const getColourStyles = (hasError) => ({
        control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: '#F9F9F9',
            borderColor: hasError ? 'red' : (isFocused ? '#339A5B' : '#D9D9D9'),
            boxShadow: hasError ? '1px red' : (isFocused ? '1px #339A5B' : null),
            '&:hover': {
                borderColor: hasError ? 'red' : (isFocused ? '#D9D9D9' : '#339A5B')
            },
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? '#ecf9ec'
                    : isFocused
                        ? '#f2f2f2'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            '&:active': {
                backgroundColor: !isDisabled && (isSelected ? 'darkgreen' : '#ecf9ec'),
                color: !isDisabled && (isSelected ? 'white' : 'black')
            }
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: zIndex
        }),
    });

    const cerrarModal = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className='w-100'>
                <h3 className='text-center'>Imagen de Editar</h3>
                <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Servicio</h3>

                <form>

                    {!loadingData ?

                        <>

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='categoria_id' className='w-56 flex items-center font-normal text-[#454545]'>Cliente: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadClientesOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.cliente_id)}
                                    id="cliente_id"
                                    //value={proveedorId}
                                    defaultValue={{ id: clienteId.id, label: `${clienteId.id} - ${clienteId.nombres} ${clienteId.apellido_paterno} ${clienteId.apellido_materno} - ${clienteId.nro_documento}` }}
                                    onChange={(option, id) => { setClienteId(option); console.log(option, id); }}
                                    placeholder="Seleccionar proveedor..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.cliente_id && Array.isArray(errors.cliente_id) && renderErrorMessages(errors.cliente_id)}

                            <div className='flex mb-3 w-full'>
                                <label htmlFor='servicio_especifico_id' className='w-56 flex items-center font-normal text-[#454545]'>Servicio: </label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadServiciosEspecificosOptions}
                                    className={`w-full`}
                                    styles={getColourStyles(!!errors.servicio_especifico_id)}
                                    id="servicio_especifico_id"
                                    /* value={servicioEspecificoId} */
                                    defaultValue={{ id: servicioEspecificoId.id, label: `${servicioEspecificoId.id} - ${servicioEspecificoId.nombre_servicio}` }}
                                    onChange={(option, id) => { setServicioEspecificoId(option); console.log(option, id); }}
                                    placeholder="Seleccionar servicio específico..."
                                    noOptionsMessage={() => "No se encontraron resultados"}
                                    loadingMessage={() => "Cargando..."}
                                    menuPortalTarget={document.querySelector('body')}
                                />
                            </div>

                            {errors.servicio_especifico_id && Array.isArray(errors.servicio_especifico_id) && renderErrorMessages(errors.servicio_especifico_id)}

                            <TextAreaForm
                                id="descripcion"
                                labelNameArea="Descripción:"
                                value={descripcion}
                                row={4}
                                cols={40}
                                funcion={(e) => setDescripcion(e.target.value)}
                                errors={errors.descripcion}
                                labelWidth="w-56"
                            />

                            {errors.descripcion && Array.isArray(errors.descripcion) && renderErrorMessages(errors.descripcion)}

                        </>

                        : <div className='flex justify-center items-center' style={{ height: '217px' }}>
                            <TailSpin className='' width={150} height={150}></TailSpin>
                        </div>}

                    <div className='flex justify-end flex-wrap mb-3 mt-6'>
                        <button
                            disabled={loadingButton}
                            className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

                            onClick={onSubmit}
                            type="submit">

                            {loadingButton ?
                                <div className='flex items-center justify-center w-52'>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth='3'
                                        width='22'
                                        radius="5"
                                        ariaLabel="lines-loading"
                                    />
                                </div>
                                : <>
                                    <MdEditDocument /> Editar Servicio
                                </>
                            }

                        </button>

                        <button
                            className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
                            onClick={cerrarModal}
                        >
                            Cerrar
                        </button>
                    </div>

                </form>
            </div>
        </Modal >
    )
}
