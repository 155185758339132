import React, { useEffect, useState } from 'react';

import Config from '../../../Config';
import AuthUser from '../../../pages/pagesAuth/AuthUser';
import InputForm from '../../inputs/InputForm';

import Modal from '../../modals/Modal';

import { RotatingLines, TailSpin } from 'react-loader-spinner';
import { MdEditDocument } from "react-icons/md";

import { toast } from 'react-toastify';

export default function ModalEditarProveedor({
  open, onClose, updateProveedorList, editId
  /* ,editRuc, editNombre, editDireccion, editTelefono, editEstado */
}) {

  const { getRol } = AuthUser();

  const id = editId;
  /* const [ruc, setRuc] = useState(editRuc);
  const [nombre, setNombre] = useState(editNombre);
  const [direccion, setDireccion] = useState(editDireccion);
  const [telefono, setTelefono] = useState(editTelefono);
  const [estado, setEstado] = useState(editEstado); */

  const [ruc, setRuc] = useState('');
  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [celular, setCelular] = useState('');
  const [estado, setEstado] = useState('');

  const [errors, setErrors] = useState({});

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingVolverBtn, setLoadingVolverBtn] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      cargarProveedor();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleClickOutside = (event) => {
    if (event.target.closest(".modal-content") === null) {
      onClose();
    }
  };

  const cargarProveedor = async () => {
    setLoadingData(true);
    setErrors({});

    try {

      let response;

      if (getRol() === "Administrador") {
        response = await Config.getOneProveedorAdmin(id);
      }

      if (response.data.exito == 0) {
        notify(`Error: ${response.data.message}`, "error");
      } else {
        setRuc(response.data.ruc);
        setNombre(response.data.nombre_proveedor);
        setDireccion(response.data.direccion);
        setCelular(response.data.celular);
        setEstado(response.data.estado_proveedor);
      }

    } catch (error) {
      console.error(error);
      notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
    } finally {
      setLoadingData(false);
    };
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoadingButton(true);

    try {

      const proveedorData = {
        nombre_proveedor: nombre,
        ruc,
        direccion,
        celular,
        estado_proveedor: estado
      };

      let response;

      if (getRol() === "Administrador") {
        response = await Config.putUpdateProveedorAdmin(id, proveedorData);
      }

      if (response.data.errors) {
        setErrors(response.data.errors);
      } else {
        if (response.data.exito == 0) {
          notify(`Error: ${response.data.message}`, "error");
        } else if (response.data.exito == 1) {
          notify("El proveedor ha sido editado correctamente.", "success");
          setRuc('');
          setNombre('');
          setDireccion('');
          setCelular('');
          setEstado('');
          onClose();
          updateProveedorList();
        } else {
          notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
        }
      }


    } catch (error) {
      console.log(error);
      updateProveedorList();
      notify("Ha ocurrido un error. Vuelva a intentarlo más tarde.", "error");
    } finally {
      setLoadingButton(false);
    };

  }

  const renderErrorMessages = (messages) => {
    return messages.map((msg, index) => (
      <div style={{ marginLeft: '170px' }}><p key={index} className="text-red-500 text-sm -mt-3 mb-3 ms-1">{msg}</p></div>
    ));
  };

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const cerrarModal = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className='w-100'>
        <h3 className='text-center'>Imagen de Editar</h3>
        <h3 className='text-lg text-center font-black text-gray-800 w-full mb-8'>Editar Proveedor</h3>

        <form>

          {!loadingData ?

            <>

              <InputForm
                id="ruc"
                labelName="RUC:"
                inputType="text"
                value={ruc}
                funcion={(e) => setRuc(e.target.value.replace(/[^0-9]/g, ""))}
                errors={errors.ruc}
                maxLength="11"
                labelWidth={'w-64'}
              />

              {errors.ruc && Array.isArray(errors.ruc) && renderErrorMessages(errors.ruc)}

              <InputForm
                id="nombre"
                labelName="Nombre de Proveedor:"
                inputType="text"
                value={nombre}
                funcion={(e) => setNombre(e.target.value)}
                errors={errors.nombre_proveedor}
                labelWidth={'w-64'}
              />

              {errors.nombre_proveedor && Array.isArray(errors.nombre_proveedor) && renderErrorMessages(errors.nombre_proveedor)}

              <InputForm
                id="direccion"
                labelName="Dirección:"
                inputType="text"
                value={direccion}
                funcion={(e) => setDireccion(e.target.value)}
                errors={errors.direccion}
                labelWidth={'w-64'}
              />

              {errors.direccion && Array.isArray(errors.direccion) && renderErrorMessages(errors.direccion)}

              <InputForm
                id="celular"
                labelName="Celular:"
                inputType="text"
                value={celular}

                funcion={(e) => setCelular(e.target.value.replace(/[^0-9]/g, "")) /* {
          const inputValue = e.target.value.replace(/[^0-9]/g, "");
          if (inputValue.length > 0 && inputValue[0] !== '9') {
            return;
          }
          setTelefono(inputValue);
        } */}

                errors={errors.celular}
                maxLength={9}
                labelWidth={'w-64'}
              />

              {errors.celular && Array.isArray(errors.celular) && renderErrorMessages(errors.celular)}

              <div className='flex mb-3'>
                <label htmlFor='estado' className='w-64 flex items-center font-normal text-[#454545]'>Estado: </label>
                <select
                  className={`w-full px-2 py-1 bg-[#F9F9F9]
          border border-[#D9D9D9] rounded-md
          focus:outline-none focus:border-[#339A5B] focus:ring-1 focus:ring-[#339A5B]
          ${errors.estado_proveedor ? 'border-red-500' : 'border-slate-300'}`}
                  id="role"
                  value={estado}
                  onChange={(e) => setEstado(e.target.value)}
                  /* value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)} */
                  required
                >
                  <option value="">Selecciona un estado</option>
                  <option value="0">Inactivo</option>
                  <option value="1">Activo</option>
                </select>
              </div>

              {errors.estado_proveedor && Array.isArray(errors.estado_proveedor) && renderErrorMessages(errors.estado_proveedor)}

            </>

            : <div className='flex justify-center items-center' style={{ height: '217px' }}>
              <TailSpin className='' width={150} height={150}></TailSpin>
            </div>}

          <div className='flex justify-end flex-wrap mb-3 mt-6'>
            <button
              disabled={loadingButton}
              className={`flex gap-2 items-center justify-center py-2 font-semibold 
                            shadow-md rounded-md w-52
                            text-white bg-[#EB8927] me-3
                            ${loadingButton ? 'cursor-not-allowed opacity-50' : ''}`}

              onClick={onSubmit}
              type="submit">

              {loadingButton ?
                <div className='flex items-center justify-center w-52'>
                  <RotatingLines
                    strokeColor="white"
                    strokeWidth='3'
                    width='22'
                    radius="5"
                    ariaLabel="lines-loading"
                  />
                </div>
                : <>
                  <MdEditDocument /> Editar Proveedor
                </>
              }

            </button>

            <button
              className='py-2 px-4 font-semibold shadow-md rounded-lg w-40
                            bg-white text-gray-500'
              onClick={cerrarModal}
            >
              Cerrar
            </button>
          </div>

        </form>
      </div>
    </Modal >
  )
}
